import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import coloredLogo from '../../resources/assets/mdg-logo.svg';

interface NavBarProps {
    className: string,
    logo: string,
    isElevated: boolean,
    activeKey: string,
    dotColor: string,
    navBarRef: React.RefObject<HTMLElement>,
}

const CustomNavbar: React.FC<NavBarProps> = props => {
    const { className, logo, isElevated, activeKey, dotColor, navBarRef } = props;
    const [isNavExpanded, setNavExpanded] = useState(false);
    const elevatedClass: string = isElevated || isNavExpanded ? ' elevated' : '';
    const isCustom = (isNavExpanded || isElevated);

    const onToggle: (expanded: boolean) => void = (expanded) => {
        setNavExpanded(expanded);
        (window as any).fullpage_api.setAllowScrolling(!expanded);
    }

    return (
        <Navbar expand="lg" fixed='top' className={'custom-nav-bar-normal' + className + elevatedClass} onToggle={onToggle} ref={navBarRef} collapseOnSelect>
            <Navbar.Brand>
                <a href="/">
                    <MdgLogo image={isCustom ? coloredLogo : logo} dotColor={dotColor} />
                </a>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav'><MenuIcon /></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto" activeKey={activeKey}>
                    <Nav.Link href="/#what-we-do-section" eventKey='1'>Projects</Nav.Link>
                    <Nav.Link href="/#community-section" eventKey='2'>Community</Nav.Link>
                    <Nav.Link href="/#podcast-section" eventKey='3'>Podcast</Nav.Link>
                    <Nav.Link href="/#about-us-section" eventKey='4'>About</Nav.Link>
                    <Nav.Link target='_blank' className='navlink-blog' href="https://medium.com/mobile-development-group">Blog</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    );
}

const MenuIcon: React.FC = () => {
    return (
        <>
            <span className='icon-bar top-bar'></span>
            <span className='icon-bar middle-bar'></span>
            <span className='icon-bar bottom-bar'></span>
        </>
    );
}

interface LogoProps {
    image: string;
    dotColor: string;
}

const MdgLogo: React.FC<LogoProps> = props => {
    const { image, dotColor } = props;
    return (
        <div className='mdg-logo' style={{ display: 'flex', flexFlow: 'row nowrap' }}>
            <div className="logo-dot" style={{ borderRadius: '50%', height: '6.5px', width: '6.5px', backgroundColor: dotColor, alignSelf: 'flex-end', margin: '0 5px 6px 0' }} />
            <img src={image} alt="mdg-logo" className="logo-image" />
        </div>
    );
}

export default CustomNavbar;
