import React from "react";
import axios from "./axios";
import { Contact } from "../components/HomePage/Home";

export const getContacts: (
  setContact: React.Dispatch<React.SetStateAction<Contact>>
) => void = (setContact) =>
  axios.get("/contact").then((response) => {
    setContact(response.data);
  });
