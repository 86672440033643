import "react-notion/src/styles.css";
import { BlockMapType, NotionRenderer } from "react-notion";
import { useEffect, useState } from "react";
import { getDevAssgnNotion } from "../../Api/notion_api";

const DevAssign: React.FC = () => {
  const [result, setResult] = useState<BlockMapType>({});

  useEffect(() => {
    getDevAssgnNotion(setResult);
  }, []);

  return (
    <div
      className="fullpage-section dev-assgn-page"
      data-anchor="dev-assgn-page"
      key="dev-assgn-page"
      id="dev-assgn-page"
    >
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <NotionRenderer blockMap={result} fullPage />
      </div>
    </div>
  );
};

export default DevAssign;
