import React, { useState } from "react";
import mdgVideo from "../../resources/assets/mdg-video.m4v";
import { Contact } from "./Home";

const Introduction: React.FC<Contact> = (props) => {
  const { chat } = props;

  return (
    <div className="introduction">
      <video
        className="introduction-video"
        autoPlay
        muted
        loop
        data-autoplay
        playsInline
      >
        <source src={mdgVideo} type="video/mp4" />
      </video>
      <div className="introduction-overlay" />
      <div className="introduction-content">
        <p className="introduction-title">MDG SPACE, IIT ROORKEE</p>
        <p className="introduction-header">Where Ideas Meet Reality</p>
        <p className="introduction-description">
        We are a bunch of enthusiastic students who aim to explore, ideate and develop products over a vast range of software technologies in the IIT Roorkee campus and beyond.
        </p>
        <a target="_blank" href={chat}>
          <button className="introduction-chat-button">Chat with us</button>
        </a>
      </div>
    </div>
  );
};

export default Introduction;
