import React from "react";
import axios from "./axios";
import { GalleryImage } from "../components/HomePage/Gallery";

export const getGalleryImages: (
  setImages: React.Dispatch<React.SetStateAction<GalleryImage[]>>
) => void = (setImages) =>
  axios.get("/gallery-images?_sort=id").then((response) => {
    setImages(response.data);
  });

export const getGalleryImagesCount: () => Promise<number> = () =>
  axios.get("/gallery-images/count").then((response) => {
    return response.data;
  });
