// @ts-nocheck
import React from "react";

class Chat extends React.Component {
  render() {
    return (
      <div
        className="fullpage-section chat-page"
        data-anchor="chat-page"
        key="chat-div"
        id="chat-div"
      >
        <frameset rows="100%">
          <frameset cols="100%">
            <frame
              src="https://chat.mdgspace.org/"
              frameborder="0"
              scrolling="no"
            />
          </frameset>
        </frameset>
      </div>
    );
  }
}

export default Chat;
