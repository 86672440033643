import "react-notion/src/styles.css";
import { BlockMapType, NotionRenderer } from "react-notion";
import { useEffect, useState } from "react";
import { getSODNotion } from "../../Api/notion_api";

const SODPage: React.FC = () => {
  const [result, setResult] = useState<BlockMapType>({});

  useEffect(() => {
    getSODNotion(setResult);
  }, []);

  return (
    <div
      className="fullpage-section sod-page"
      data-anchor="sod-page"
      key="sod-page"
      id="sod-page"
    >
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <NotionRenderer blockMap={result} fullPage />
      </div>
    </div>
  );
};

export default SODPage;
