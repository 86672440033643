import React, { useEffect, useState } from "react";
import Flickity, { FlickityOptions } from "react-flickity-component";
import { getYearsList, getDatePercentage } from "../../Utils/date_utils";
import { getTimelineEvents } from "../../Api/timeline_events_api";

export interface TimelineEvent {
    image: { url: string },
    date: string,
    timelineImage: { url: string },
    title: string,
    color: string,
    link?: string,
    TimelineBarDate?: string,
}

const flickityOptions: FlickityOptions = {
    autoPlay: false,
    pageDots: false,
    cellAlign: 'center',
    adaptiveHeight: true,
}

const TimeLine: React.FC = function () {
    let flick = new Flickity({});
    const [index, setIndex] = useState(0);
    const [flckty, setFlckty] = useState<Flickity>(flick);

    const initFlcty = (ref: any) => {
        setFlckty(ref);
    }

    const [timelineEvents, setTimelineEvents] = useState<TimelineEvent[]>([]);
    timelineEvents.sort((e1, e2) => e1.date.localeCompare(e2.date));

    useEffect(() => {
        if (flckty.selectedIndex !== undefined) {
            flckty.on('change', (() => {
                setIndex(flckty.selectedIndex);
            }))
        }
    }, [flckty.selectedIndex])

    useEffect(() => {
        getTimelineEvents(setTimelineEvents);
    }, []);

    useEffect(() => {
        (window as any).fullpage_api.reBuild();
    }, [timelineEvents]);

    return (
        <>
            <div className="timeline-heading">Our History</div>
            <div className="flickity-wrapper">
                <Flickity
                    options={flickityOptions}
                    flickityRef={initFlcty}
                    className="timeline-flickity"
                >
                    {timelineEvents.map((event: TimelineEvent) => {
                        let date = new Date(event.date);
                        return (
                            <div className="timeline-event">
                                <div className="timeline-image-container"><img className='timeline-image' src={event.image.url} alt='event' /></div>
                                <a className="timeline-event-title" href={event.link} target="_blank">{event.title}</a>
                                <div className="timeline-event-date">{date.toLocaleDateString('en-us', { day: 'numeric', month: 'long', year: 'numeric' })}</div>
                            </div>
                        );
                    })}
                </Flickity>
            </div>
            <TimeLineBar flktyRef={flckty} selectedIndex={index} eventList={timelineEvents} />
        </>
    );
}

interface TimeLinebarProps {
    flktyRef: Flickity,
    selectedIndex: number,
    eventList: TimelineEvent[],
}

const TimeLineBar: React.FC<TimeLinebarProps> = props => {
    const { flktyRef, selectedIndex, eventList } = props;

    return (
        <div className="timeline-bar">
            <div className="timeline-bar-items">
                {eventList.map((event: TimelineEvent, index: number) => {
                    return (
                        <div style={{ left: `${getDatePercentage(event.TimelineBarDate === null ? event.date : event.TimelineBarDate!)}%` }} className={`timeline-bar-item ${selectedIndex === index ? 'timeline-bar-item-active' : ''}`} onClick={() => { flktyRef.select(index); }}>
                            <span className="timeline-circle" style={{ backgroundColor: event.color }}></span>
                            <img className="timeline-bar-image" src={event.timelineImage.url} alt="" />
                        </div>
                    );
                })}
            </div>
            <div className="timeline-years-list">
                {getYearsList().map((year: number) => {
                    return (
                        <div className="timeline-year" style={{ left: `${getDatePercentage((new Date(year, 0, 1, 5, 30)).toDateString())}%` }}>{year}</div>
                    );
                })}
            </div>
        </div>
    );
}

export default TimeLine
