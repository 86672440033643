import React from 'react';
import MemberCard, { MemberCardProp } from './MemberCard';


export interface MemeberCardLayoutProp {
    list: MemberCardProp[],
}

const CardLayout: React.FC<MemeberCardLayoutProp> = props => {

    return (
        <div className="card-layout">
            {props.list.map((cardProp: MemberCardProp) => <MemberCard {...cardProp} />)}
        </div>
    )
}

export default CardLayout;
