import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem, { CarouselItemProps } from './ProjectItem';
import prevIcon from '../../resources/assets/prev_icon.svg';
import nextIcon from '../../resources/assets/next_icon.svg';
import { Product } from '../HomePage/WhatWeDo';
import { getProjectItems } from '../../Api/project_items_api';
import ProductOverlay from '../BottomProductsBar';
import { useParams } from 'react-router-dom';

interface ProjectsPageProps {
    setColor: React.Dispatch<React.SetStateAction<string>>,
}

const ProjectPage: React.FC<ProjectsPageProps> = props => {
    const { setColor } = props;

    let params: any = useParams()
    const [index, setIndex] = useState(Number(params.id));
    const [projectData, setProjectData] = useState<CarouselItemProps[]>([]);
    const [productList, setProductList] = useState<Product[]>([]);
    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        getProjectItems(setProjectData);
    }, []);

    useEffect(() => {
        setProductList(projectData.map((data, i) => {
            const appData: Product = {
                name: data.name,
                image: data.logo.url,
                color: data.color!,
                textColor: data.text_color!,
                index: i,
            }
            return appData;
        }));
    }, [projectData]);

    useEffect(() => {
        if (projectData.length != 0) {
            console.log(projectData);
            let selectedProject: CarouselItemProps = projectData[index];
            console.log(selectedProject);
            setColor(selectedProject.button_color == null ? selectedProject.color! : selectedProject.button_color!);
        }
    }, [index, projectData]);

    return (

        <div className='fullpage-section fp-auto-height-responsive' data-anchor='projects' style={{ height: "auto" }} key='projects-page' id='projects-page'>
            <div className='project-body'>
                <Carousel indicators={false} controls={true} nextIcon={<Next />} prevIcon={<Previous />} interval={null} activeIndex={index} onSelect={handleSelect} onSlide={handleSelect}>
                    {projectData.map((data, i) => {
                        return (<Carousel.Item tabIndex={data.id}><CarouselItem
                            name={data.name}
                            logo={data.logo}
                            desc_img={data.desc_img}
                            title={data.title}
                            desc={data.desc}
                            button_text={data.button_text}
                            button_link={data.button_link}
                            product_type={data.product_type}
                            color={data.color}
                            text_color={data.text_color}
                            id={i}
                            contribution_link={data.contribution_link}
                            button_color={data.button_color}
                        /></Carousel.Item>)
                    })}
                </Carousel>
                <div style={{ overflow: 'hidden' }}>
                    <ProductOverlay productList={productList} selectedIndex={index} setSelectedIndex={setIndex}/>
                </div>
            </div>
        </div>
    );
}

const Previous: React.FC = () => {
    return <img className='control-prev' src={prevIcon} alt='prev' />
}

const Next: React.FC = () => {
    return <img className='control-next' src={nextIcon} alt='next' />
}

export default ProjectPage;
