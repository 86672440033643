import React from "react";
import axios from "./axios";
import { CarouselItemProps } from "../components/ProjectPage/ProjectItem";

export const getProjectItems: (
  setData: React.Dispatch<React.SetStateAction<CarouselItemProps[]>>
) => void = (setData) =>
  axios.get("/project-items?_sort=order").then((response) => {
    setData(response.data);
  });

export const getProjectItemsCount: () => Promise<number> = () =>
  axios.get("/project-items/count").then((response) => {
    return response.data as Promise<number>;
  });
