import React, { Component } from "react";

interface ProductCardProps {
    image?: string;
    name?: string;
    color?: string;
    link?: string;
    textColor: string;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    selected?: boolean;
}

class ProductCard extends Component<ProductCardProps> {
    render() {
        const { image, name, color, link, textColor, setIndex, index, selected } = this.props;
        return (
            <div className={`product-container ${selected! ? 'product-container-active' : ''}`}
                style={{ backgroundColor: color, }}
                onClick={() => {
                    setIndex(index);
                }}
            >
                <img src={image} alt="" />
                <span style={{ color: textColor }}> {name} </span>
            </div>
        );
    }
}

export default ProductCard;
