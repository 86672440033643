import React, { useState } from 'react';
import { SocialMedia } from '../HomePage/Footer';
import { CustomModal } from './TeamsPage';

export interface MemberCardProp {
    name: string;
    domain: string;
    backgroundColor: string;
    image: { url: string };
    backgroundImage: { url: string };
    social_medias: SocialMedia[];
    desc: string;
    modal_img: { url: string }[];
    modal_interests: string;
    modal_contri: string;
};

const MemberCard: React.FC<MemberCardProp> = props => {
    const { name, domain, image, backgroundColor, backgroundImage, social_medias, desc, modal_img, modal_contri, modal_interests } = props;
    const [show, setShow] = useState<boolean>(false);
    return (
        <div className='member-card-container'>
            <div className='member-card' onClick={() => setShow(true)}>
                <div className='member-card-div' style={{ backgroundColor: backgroundColor }} />
                <img className='member-card-background' src={backgroundImage.url} />
                <img className='member-card-image' src={image.url} />
            </div>
            <div className='member-card-info'>
                <p className='member-card-text'>{name}</p>
                <p className='member-card-sub-text'>{domain.toLocaleLowerCase()}.mdg</p>
            </div>
            <CustomModal show={show} onHide={() => setShow(false)} desc={desc} modal_img={modal_img} social_medias={social_medias} modal_contri={modal_contri} modal_interests={modal_interests}></CustomModal>
        </div>
    );
}

export default MemberCard;
