import React from "react";

interface ProjectOverlayProps {
    image: string
}

const ProjectOverlay: React.FC<ProjectOverlayProps> = props => {
    const { image } = props;
    return <img alt="appetizer-img" className='mobile-overlay' src={image} />
}

export default ProjectOverlay;
