import React from "react";
import axios from "./axios";
import { MemberCardProp } from "../components/TeamsPage/MemberCard";

export const getMembers: (
  setMembers: React.Dispatch<React.SetStateAction<MemberCardProp[]>>
) => void = (setMembers) =>
  axios.get("/members?_sort=year:desc,name").then((response) => {
    setMembers(response.data);
  });

export const getMembersCount: () => Promise<number> = () =>
  axios.get("/members/count").then((response) => {
    return response.data;
  });
