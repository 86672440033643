import React from 'react';


const MobileOverlay: React.FC = () => {
    return (
        <div className='mobile-overlay'>
            <div className='product-overlay'>
                <p className='product-overlay-heading'>MDG PROJECTS HUB</p>
            </div>
        </div>
    );
}

export default MobileOverlay;
