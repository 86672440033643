import React from "react";
import Mobile from '../HomePage/Mobile';
import MobileOverlayMobile from './MobileOverlayMobile';

const MobileWhatWeDo: React.FC = () => {
    return (
        <div className={`mobile-what-we-do`}>
            <div className="mobile-what-we-do-wrapper">
                <div className="what-we-do-info">
                    <p className="what-we-do-header">We Engineer Robust Experiences</p>
                    <p className="what-we-do-description">In the transition from Mobile Development Group to MDG Space, our scope has expanded beyond mobile development and we’re on the expedition to explore a new ecosystem of technologies through our own medium “.mdg”.</p>
                </div>
                <Mobile content={<MobileOverlayMobile />} />
            </div>
        </div>
    );
}

export default MobileWhatWeDo;
