import React, { Component } from "react";
import { Link } from "react-router-dom";

interface IMobileAppLibraryProps {
    productType?: string;
    image?: string;
    name?: string;
    index: number;
}

class MobileAppLibrary extends Component<IMobileAppLibraryProps> {
    render() {
        const { image, name, productType, index } = this.props;
        return (
            <div className="mobile-app-library-wrapper">
                <Link to={{ pathname: `/project/${index}` }}>
                    <div
                        className={
                            productType === "library" ? "library-container" : "app-container"
                        }
                    >
                        <img src={image} alt="" />
                        <span>{name}</span>
                    </div>
                </ Link>
            </div>
        );
    }
}

export default MobileAppLibrary;
