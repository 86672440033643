import React from "react";
import axios from "./axios";
import { MdgSocialMedia } from "../components/HomePage/Footer";

export const getMdgSocialMedia: (
  setMdgSocialMedia: React.Dispatch<React.SetStateAction<MdgSocialMedia[]>>
) => void = (setMdgSocialMedia) =>
  axios.get("/mdg-social-medias").then((response) => {
    setMdgSocialMedia(response.data);
  });

export const getSocialMediaCount: () => Promise<number> = () =>
  axios.get("/mdg-social-media/count").then((response) => {
    return response.data;
  });
