import React, { useEffect, useState } from 'react';
import { getPodcastVideo } from '../../Api/podcast_video_api';
import { Contact } from './Home';


export interface PodcastVideoData {
    podcast_video: string;
}

const PodcastSection: React.FC<Contact> = props => {
    const [podcastData, setPodcastData] = useState<PodcastVideoData>({ podcast_video: '' });
    const { youTube } = props;

    useEffect(() => {
        getPodcastVideo(setPodcastData);
    }, []);


    return (
        <>
            <div className='podcast-section'>
                <p className='podcast-section-header'>Nobody is hard to reach, anymore</p>
                <div className='podcast-section-video-container'>
                    <iframe className='podcast-section-video' src={podcastData.podcast_video} loading='lazy' title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                {/* <p className='podcast-section-quote-header'>Your Dose of Motivation is already here</p> */}
                <p className='podcast-section-quote-body'>MDG Talks is a chronicle of the lives of IITR alum and the amazing things they do after Roorkee. Your daily dose of motivation and existential crisis. You're welcome.</p>
                <a target='_blank' href={youTube} className='podcast-section-button'>Go to MDG Talks</a>
            </div>
        </>
    );
}

export default PodcastSection;
