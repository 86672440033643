import React, { useEffect, useState } from "react";
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem, { CarouselItemProps } from '../ProjectPage/ProjectItem';
import prevIcon from '../../resources/assets/prev_icon.svg';
import nextIcon from '../../resources/assets/next_icon.svg';
import MobileOverlay from "./MobileOverlay";
import Mobile from "./Mobile";
import ProductOverlay from "../BottomProductsBar";
import { getProjectItems } from "../../Api/project_items_api";

type Product = {
    name: string;
    image: string;
    color: string;
    textColor: string;
    index: number;
}

interface WhatWeDoProps {
    setColor: React.Dispatch<React.SetStateAction<string>>,
}

const WhatWeDo: React.FC<WhatWeDoProps> = props => {

    const { setColor } = props;
    const [projectData, setProjectData] = useState<CarouselItemProps[]>([]);
    const [productList, setProductList] = useState<Product[]>([]);
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex: number, e: any) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        console.log("component rendered");
        getProjectItems(setProjectData);
    }, []);

    useEffect(() => {
        setProductList(projectData.map((data, i) => {
            const appData: Product = {
                name: data.name,
                image: data.logo.url,
                color: data.color!,
                textColor: data.text_color!,
                index: i + 1,
            }
            return appData;
        }));
    }, [projectData])

    useEffect(() => {
        console.log("function called 2");
        if (index != 0) {
            let selectedProject: CarouselItemProps = projectData[index - 1];
            setColor(selectedProject.button_color == null ? selectedProject.color! : selectedProject.button_color!);
        }
        else setColor('#000000');
    }, [index]);

    return (
        <div className={`what-we-do`}>
            <div className="what-we-do-wrapper">
                <Carousel indicators={false} controls={true} nextIcon={<Next />} prevIcon={<Previous />} interval={null} keyboard={true} activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item style={{ height: '100vh' }} className='first-carousel'>
                        <div className="what-we-do-info">
                            <p className="what-we-do-header">We Engineer Robust Experiences</p>
                            <p className="what-we-do-description">In the transition from Mobile Development Group to MDG Space, our scope has expanded beyond mobile development and we’re on the expedition to explore a new ecosystem of technologies through our own medium “.mdg”.</p>
                        </div>
                        <Mobile
                            content={<MobileOverlay />} />
                    </Carousel.Item>

                    {projectData.map((data, i) => {
                        return (<Carousel.Item className='projects-overview-carousel' tabIndex={data.id}><CarouselItem
                            name={data.name}
                            logo={data.logo}
                            desc_img={data.desc_img}
                            title={data.title}
                            desc={data.desc}
                            button_text={data.button_text}
                            button_link={data.button_link}
                            product_type={data.product_type}
                            color={data.color}
                            text_color={data.text_color}
                            id={i + 1}
                            contribution_link={data.contribution_link}
                            type={data.type}
                            button_color={data.button_color}
                        /></Carousel.Item>)

                    })}
                </Carousel>
                <ProductOverlay productList={productList} selectedIndex={index} setSelectedIndex={setIndex}/>
            </div>
        </div>
    );
}

const Previous: React.FC = () => {
    return <img className='control-prev' src={prevIcon} alt='prev' />
}

const Next: React.FC = () => {
    return <img className='control-next' src={nextIcon} alt='next' />
}

export default WhatWeDo;
export type { Product };
