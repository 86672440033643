import React, { useEffect, useState } from 'react';
import MobileAppLibrary from "./MobileAppLibrary";
import DragableScroll from "../DragableScroll";
import { CarouselItemProps } from '../ProjectPage/ProjectItem';
import { getProjectItems } from '../../Api/project_items_api';

const MobileOverlayMobile: React.FC = () => {

    const [projectData, setProjectData] = useState<CarouselItemProps[]>([]);
    const [appProductList, setAppProductList] = useState<MobileProduct[]>([]);
    const [libraryProductList, setLibraryProductList] = useState<MobileProduct[]>([]);


    useEffect(() => {
        getProjectItems(setProjectData);
    }, []);

    useEffect(() => {
        let applist: MobileProduct[] = [];
        let libraryList: MobileProduct[] = [];
        projectData.map((data , i) => {
            let appData: MobileProduct = {
                name: data.name,
                image: data.logo.url,
                index: i,
            }
            if (data.product_type === 'app') {
                applist.push(appData);
            } else {
                libraryList.push(appData);
            }

        });
        setAppProductList(applist);
        setLibraryProductList(libraryList);
    }, [projectData])

    return (
        <div className='mobile-overlay'>
            <div className='product-overlay'>
                <p className='product-overlay-heading'>MDG PROJECTS HUB</p>
                <ProductOverlay productHeading='Apps' productType='app' productList={appProductList} />
                <ProductOverlay productHeading='Libraries' productType='library' productList={libraryProductList} />
            </div>
        </div>
    );
}


type MobileProduct = {
    name: string;
    image: string;
    index: number;
}

interface MobileProductOverlayProps {
    productHeading: string;
    productType: string;
    productList: MobileProduct[];
}

const ProductOverlay: React.FC<MobileProductOverlayProps> = props => {
    const { productHeading, productType, productList } = props;
    return (
        <>
            <p className = {`${productType}-heading`}>{productHeading}</p>
            <div className={`${productType}-wrapper`}>
                <div className = 'product-wrapper-wrapper'>
                    <DragableScroll rootClass={`product-wrapper-${productType}`} >
                        {productList.map(({ name, image, index }) => {
                            return (
                                <MobileAppLibrary productType={productType} image={image} name={name} index={index} />
                            );
                        })}
                    </DragableScroll>
                </div>
            </div>
        </>
    );
}

export default MobileOverlayMobile;
