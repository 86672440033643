import React, { useEffect, useState } from 'react';
import Flickity, { FlickityOptions } from 'react-flickity-component';
import { getGalleryImages } from '../../Api/gallery_api';

const flickityOptions: FlickityOptions = {
    autoPlay: true,
    pageDots: false,
    prevNextButtons: false,
    contain: true,
}

export interface GalleryImage {
    image: { url: string },
}

const Gallery: React.FC = () => {

    const [images, setImages] = useState<GalleryImage[]>([]);

    useEffect(() => {
        getGalleryImages(setImages);
    }, []);

    useEffect(() => {
        (window as any).fullpage_api.reBuild();
    }, [images]);

    return (
        <>
            <div className="gallery-title">Gallery</div>
            <Flickity options={flickityOptions} className='gallery-image-container'>
                {images.map((galleryImage) => <div className="gallery-image-wrapper"><img className='gallery-image' src={galleryImage.image.url} /></div>)}
            </Flickity>
            <div className="gallery-description">
                <div className="gallery-description-text">Wondering who all made this happen?</div>
                <a href='/team' className='podcast-section-meet-button'>MEET THE TEAM</a>
            </div>
        </>
    );
}

export default Gallery;
