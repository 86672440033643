import React, { useEffect } from "react";

interface RedirectToExternalWebsiteProps {
  externalUrl: string;
}

const RedirectToExternalWebsite: React.FC<RedirectToExternalWebsiteProps> = ({ externalUrl }) => {
  useEffect(() => {
    window.location.href = externalUrl;
  }, [externalUrl]);
  return <div>Redirecting...</div>;
};

export default RedirectToExternalWebsite;
