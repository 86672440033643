import React, { useEffect, useRef, useState } from 'react';
import Footer, { SocialMedia } from '../HomePage/Footer';
import CardLayout from './CardLayout';
import { MemberCardProp } from './MemberCard'
import { Contact } from '../HomePage/Home';
import { getMembers } from '../../Api/members';
import { getContacts } from '../../Api/contacts';
import { Modal } from 'react-bootstrap';
import close from '../../resources/assets/cross.svg';

interface TeamsPageProps{
    setDotColor: React.Dispatch<React.SetStateAction<string>>
}

const TeamsPage: React.FC<TeamsPageProps> = props => {
    const {setDotColor} = props;
    const [contact, setContact] = useState<Contact>({ chat: '', phone: '', email: '', youTube: '' });
    const [memberList, setMemberList] = useState<MemberCardProp[]>([]);

    useEffect(() => {
        getMembers(setMemberList);
        getContacts(setContact);
    }, []);

    useEffect(() => {
        setDotColor('#000000');
        (window as any).fullpage_api.reBuild()
    }, [contact, memberList]);

    return (
        <div className='fullpage-section teams-page' data-anchor='teams-page' key='teams-div' id='teams-div' >
            <div className='teams-page-div'>
                <p className='teams-page-header'>Team MDG 2022</p>
                <CardLayout list={memberList} />
                <Footer {...contact} />
            </div>
        </div>
    );
}

interface ModalProps {
    show: boolean,
    onHide: () => void,
    desc: string,
    modal_img: { url: string }[],
    modal_interests: string,
    modal_contri: string,
    social_medias: SocialMedia[],
}

export const CustomModal: React.FC<ModalProps> = props => {
    const { show, onHide, desc, modal_img, modal_interests, modal_contri, social_medias } = props;
    return (
        <Modal
            show={show}
            onHide={onHide}
            className='member-card-modal'
            size="lg"
            aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <div className="member-card-modal-body">
                    <div className="member-card-modal-content">
                        <div className="member-card-modal-profile-container">
                            <img src={modal_img[0]?.url} className="member-card-modal-profile-image" />
                            <div className="footer-social-link large">
                                {social_medias.map((mediaProp: SocialMedia) => <a target='_blank' href={mediaProp.url}><img className='footer-social-media' src={mediaProp.image.url} alt={mediaProp.name}></img></a>)}
                            </div>
                        </div>
                        <div className="member-card-modal-info-container">
                            <p className="member-card-modal-info">{desc}</p>
                            {modal_interests && <p className="member-card-modal-intreset">Interests : {modal_interests}</p>}
                            {modal_contri && <p className="member-card-modal-contributions">Contributions : {modal_contri}</p>}
                            <div className="footer-social-link small">
                                {social_medias.map((mediaProp: SocialMedia) => <a target='_blank' href={mediaProp.url}><img className='footer-social-media' src={mediaProp.image.url} alt={mediaProp.name}></img></a>)}
                            </div>
                        </div>
                    </div>
                    <img src={close} className="member-card-modal-body-close-button" onClick={onHide} />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default TeamsPage;
