import "react-notion/src/styles.css";
import { BlockMapType, NotionRenderer } from "react-notion";
import { useEffect, useState } from "react";
import { getDesAssgnNotion } from "../../Api/notion_api";

const DesAssign: React.FC = () => {
  const [result, setResult] = useState<BlockMapType>({});

  useEffect(() => {
    getDesAssgnNotion(setResult);
  }, []);

  return (
    <div
      className="fullpage-section des-assgn-page"
      data-anchor="des-assgn-page"
      key="des-assgn-page"
      id="des-assgn-page"
    >
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <NotionRenderer blockMap={result} fullPage />
      </div>
    </div>
  );
};

export default DesAssign;
