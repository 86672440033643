import React, { useEffect, useState } from 'react'
import Introduction from './Introduction';
import WhatWeDo from './WhatWeDo';
import Community from './Community';
import Footer from './Footer';
import PodcastSection from './Podcast';
import { getContacts } from '../../Api/contacts';
import MobileWhatWeDo from './MobileWhatWeDo';
import TimeLine from './Timeline';
import Gallery from './Gallery';

export interface Contact {
    chat: string,
    phone: string,
    email: string,
    youTube: string,
}

interface HomeProps {
    setColor: React.Dispatch<React.SetStateAction<string>>,
}

const Home: React.FC<HomeProps> = props => {

    const [contact, setContact] = useState<Contact>({ chat: '', phone: '', email: '', youTube: '' });
    const { setColor } = props;

    useEffect(() => {
        getContacts(setContact);
    }, []);

    return (
        <>
            <div>
                <div key="intro-div" id="intro-div" className='fullpage-section' data-anchor='intro-section'>
                    <Introduction {...contact} />
                </div>
                <div key="mobile-div" id="mobile-div" className='fullpage-section' data-anchor='what-we-do-section'>
                    <WhatWeDo setColor={setColor} />
                    <MobileWhatWeDo />
                </div>
                <div key="community-div" id="community-div" className='fullpage-section' data-anchor='community-section'>
                    <Community />
                </div>
                <div key='podcast' id='podcast' className='fullpage-section' data-anchor='podcast-section'>
                    <PodcastSection {...contact} />
                </div>
                <div key='about-us' id="about-us" className="fullpage-section" data-anchor='about-us-section'>
                    <div className='bottom-section'>
                        <TimeLine />
                        <Gallery />
                        <Footer {...contact} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;
