import { BlockMapType } from "react-notion";
import axios from "./axios";

export const getSOCDNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/Hack8all-Hackathon-4cecdcfce2a64d45afbb9d9404e36440")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getSOCNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/Season-of-Code-217a77ca3abf489faf20d66765e99ccb")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getSOCPSNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/Problem-Statements-SoC-546980fd63ee4a178143f05e2e2357b5")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getSODNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/Season-of-Design-baa882d2e11f42378f5f220c99c89632")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getOSONotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/Open-Source-Odyssey-91184da005e14f819a6f2f77351798e0")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getDevAssgnNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get(
      "notion-proxy/MDG-Space-Dev-Assignments-12925e1f99e04140a79b6633b8a2ed5a"
    )
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getDesAssgnNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get(
      "notion-proxy/MDG-Space-Design-Assignments-f6cc48f180cf4ba498cb12ed54832ddf"
    )
    .then((response) => {
      setNotionData(response.data);
    });
};
export const getDevTaskNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/MDG-Space-Dev-Tasks-98499e47c3a341cba495f9a9583569eb")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getNebulaNotion: (
  setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
) => void = (setNotionData) => {
  axios
    .get("notion-proxy/Nebula-8fc9d9a64a484b0b814dedd65c35be19")
    .then((response) => {
      setNotionData(response.data);
    });
};

export const getArduinoNotion: (
    setNotionData: React.Dispatch<React.SetStateAction<BlockMapType>>
  ) => void = (setNotionData) => {
    axios
      .get("notion-proxy/Arduino-Guide-70bf9aee623442779ee287f9edfb991a")
      .then((response) => {
        setNotionData(response.data);
      });
  };
  
