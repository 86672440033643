const YEAR_OF_FOUNDATION = 2013;

export const getYearsList: () => number[] = () => {
  const currYear: number = new Date().getFullYear();
  let yearArray: number[] = [];
  for (let year: number = YEAR_OF_FOUNDATION; year <= currYear; year++) {
    yearArray.push(year);
  }
  return yearArray;
};

export const getDatePercentage: (dateString: string) => number = (
  dateString
) => {
  let foundationDate: Date = new Date(YEAR_OF_FOUNDATION, 0, 1, 5, 30);
  let eventDate: Date = new Date(dateString);
  let currDate: Date = new Date();
  let eventTime: number = foundationDate.getTime() - eventDate.getTime();
  let currTime: number = foundationDate.getTime() - currDate.getTime();
  return (eventTime / currTime) * 100;
};
