import React from 'react';

interface MobileProps {
    content: React.ReactNode;
    type ?: string;
};

const Mobile: React.FC<MobileProps> = props => {
    const { content , type} = props;
    return <div className={`mobile-div-container ${type! === 'mobile' ? 'mobile-div-container-mobile' : ''}`}>
        <div className="mobile-div">
            {content}
        </div>
    </div>
}

export default Mobile;
