import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "../components/HomePage/Home";
import ProjectPage from "../components/ProjectPage/Project";
import ReactFullpage from "@fullpage/react-fullpage";
import logo from "../resources/assets/mdg-logo-white.svg";
import CustomNavbar from "../components/HomePage/CustomNavBar";
import TeamsPage from "../components/TeamsPage/TeamsPage";
import ChatPage from "../components/ChatPage";
import GA from "../components/GoogleAnalytics";
import SOCDPage from "../components/SOCDPage";
import SOCPage from "../components/SOCPage";
import SODPage from "../components/SODPage";
import OSOPage from "../components/OSOPage";
import DevAssign from "../components/DevAssign";
import DesAssign from "../components/DesAssign";
import DevTask from "../components/DevTask";
import NebulaPage from "../components/NebulaPage";
import ArduinoPage from "../components/ArduinoPage";
import SOCPSPage from "../components/SOCPSPage";
import RedirectToExternalWebsite from "../components/RedirectToExternalWebsite/RedirectToExternalWebsite";

const App: React.FC = function () {
  const navBarRef: React.RefObject<HTMLElement> = React.createRef();
  const [navBarState, setNavNarState] = useState(" scrollUp");
  const [navBarActive, setNavBarActive] = useState("0");
  const [navBarElevatedState, setNavBarElevatedState] = useState(false);
  const [dotColor, setDotColor] = useState("#ffffff");
  // color stores color of the current active project in what we do section
  const [color, setColor] = useState("#000000");

  useEffect(() => {
    setDotColor(color);
  }, [color]);

  useEffect(() => {
    setDotColor("#ffffff");
  }, []);

  return (
    <div className="App">
      <Router>
        {GA.init() && <GA.RouteTracker />}
        <CustomNavbar
          className={navBarState}
          logo={logo}
          isElevated={navBarElevatedState}
          activeKey={navBarActive}
          navBarRef={navBarRef}
          dotColor={dotColor}
        />
        <ReactFullpage
          scrollOverflow={true}
          sectionSelector=".fullpage-section"
          touchSensitivity={7}
          recordHistory={false}
          responsiveWidth={480}
          dragAndMove={"fingersonly"}
          onLeave={function (origin, destination, direction) {
            setNavNarState(" scrollDown");
            if (destination.isFirst === true) {
              setNavBarElevatedState(false);
            } else {
              setNavBarElevatedState(true);
            }

            switch (destination.anchor) {
              case "intro-section": {
                setNavBarActive("0");
                setDotColor("#ffffff");
                break;
              }

              case "what-we-do-section": {
                setDotColor(color);
                setNavBarActive("1");
                break;
              }

              case "community-section": {
                setDotColor("#000000");
                setNavBarActive("2");
                break;
              }

              case "podcast-section": {
                setDotColor("#000000");
                setNavBarActive("3");
                break;
              }

              case "about-us-section": {
                setDotColor("#000000");
                setNavBarActive("4");
                break;
              }
            }
          }}
          afterLoad={function (origin, destination, direction) {
            if (direction != null) {
              setNavNarState(" scrollUp");
            } else {
              if (origin.anchor === "intro-section") {
                setNavBarElevatedState(false);
                setDotColor("#ffffff");
              } else {
                if (
                  origin.anchor === "soc-page" ||
                  origin.anchor === "soc-ps-page" ||
                  origin.anchor === "oso-page" ||
                  origin.anchor === "sod-page" ||
                  origin.anchor === "arduino-page" ||
                  origin.anchor === "socd-page"
                ) {
                  setDotColor("#000000");
                }
                setNavBarElevatedState(true);
              }
            }
          }}
          afterResponsive={function (isResponsive) {
            console.log("AFter responsive fired");
            (window as any).fullpage_api.reBuild();
          }}
          render={({ state, fullpageApi }) => {
            return (
              <>
                <ReactFullpage.Wrapper>
                  <Switch>
                    <Route exact path="/">
                      <Home setColor={setColor} />
                    </Route>
                    <Route exact path="/team">
                      <TeamsPage setDotColor={setDotColor} />
                    </Route>
                    <Route exact path="/project/:id">
                      <ProjectPage setColor={setColor} />
                    </Route>
                    <Route exact path="/chat">
                      <ChatPage />
                    </Route>
                    <Route exact path="/hack8all">
                      <SOCDPage />
                    </Route>
                    <Route exact path="/soc">
                      <SOCPage />
                    </Route>
                    <Route exact path="/soc-ps">
                      <SOCPSPage />
                    </Route>
                    <Route exact path="/open-source-odyssey">
                      <OSOPage />
                    </Route>
                    <Route exact path="/sod">
                      <SODPage />
                    </Route>
                    <Route exact path="/recruitments/2023/dev-assignments">
                      <DevAssign />
                    </Route>
                    <Route exact path="/recruitments/2023/design-assignments">
                      <DesAssign />
                    </Route>
                    <Route exact path="/recruitments/2023/dev-tasks">
                      <DevTask />
                    </Route>
                    <Route exact path="/nebula">
                      <NebulaPage />
                    </Route>
                    <Route exact path="/arduino">
                      <ArduinoPage />
                    </Route>
                    <Route exact path="/rec">
                      <RedirectToExternalWebsite externalUrl="https://www.youtube.com/live/Fu2txw0nfAM" />
                    </Route>
                  </Switch>
                </ReactFullpage.Wrapper>
              </>
            );
          }}
        />
      </Router>
    </div>
  );
};

export default App;
