import React from "react";
import axios from "./axios";
import { PodcastVideoData } from "../components/HomePage/Podcast";

export const getPodcastVideo: (
  setVideo: React.Dispatch<React.SetStateAction<PodcastVideoData>>
) => void = (setVideo) =>
  axios.get("/podcast-video").then((response) => {
    setVideo(response.data);
  });
