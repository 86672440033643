import ProductCard from "./HomePage/ProductCard";
import DragableScroll from "./DragableScroll";
import cancelIcon from "../resources/assets/project-list-cancel.svg";
import showIcon from "../resources/assets/show-project-icon.svg";
import type { Product } from "./HomePage/WhatWeDo";
import React, { useEffect, useState } from "react";
import { SwipeableHandlers, useSwipeable } from "react-swipeable";

interface ProductOverlayProps {
    productList: Product[];
    selectedIndex?: number;
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

const ProductOverlay: React.FC<ProductOverlayProps> = (props) => {
    const { productList, selectedIndex, setSelectedIndex } = props;
    const [showList, setShowList] = useState<boolean>(true);
    const handler: SwipeableHandlers = useSwipeable({
        onSwipedUp: () => setShowList(true),
        onSwipedDown: () => setShowList(false),
        delta: 4,
        preventDefaultTouchmoveEvent: true,
    });

    const isMobile: boolean = window.matchMedia("(max-width: 480px)").matches;

    useEffect(() => {
        setShowList(false);
        if (!isMobile) {
            if (selectedIndex == 0) {
                setShowList(true);
            }
        }
    }, [selectedIndex]);

    return (
        <>
            <div
                {...handler}
                className={`product-wrapper ${showList ? "" : "hidden"}`}
            >
                {isMobile || selectedIndex != 0 ? 
                    <div className="toggle-products-content" onClick={() => setShowList(!showList)} >
                        <img src={showList ? cancelIcon : showIcon} className="show-products-icon" />
                        {!showList ? 
                            <div className="show-products-text">See List of Projects</div>
                            : null
                        }
                    </div>
                    : null
                }
                <DragableScroll rootClass="product-wrapper-product">
                    {!isMobile ?
                        <div className={`product-container first-project ${selectedIndex == 0 ? "product-container-active" : ""}`} onClick={() => setSelectedIndex(0)} />
                        : null
                    }
                    {productList.map(({ name, image, color, textColor, index }) => {
                        let selected: boolean;
                        selected = selectedIndex === index;
                        return (
                            <ProductCard
                                image={image}
                                name={name}
                                color={color}
                                textColor={textColor}
                                setIndex={setSelectedIndex}
                                index={index}
                                selected={selected}
                            />
                        );
                    })}
                </DragableScroll>
            </div>
        </>
    );
};

export default ProductOverlay;
