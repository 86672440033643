import React from "react";
import axios from "./axios";
import { CardProps } from "../components/HomePage/Card";

export const getEvents: (
  setEvents: React.Dispatch<React.SetStateAction<CardProps[]>>
) => void = (setEvents) =>
  axios.get("/community-events?_sort=id").then((response) => {
    setEvents(response.data);
  });

export const getEventsCount: () => Promise<number> = () =>
  axios.get("/community-events/count").then((response) => {
    return response.data;
  });
