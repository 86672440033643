import React, { useEffect, useState } from 'react';
import CardContainerComponent from '../PodcastPage/CardContainer';
import { getEvents } from '../../Api/community_events_api';
import { CardProps } from './Card';

const Community: React.FC = () => {
    const [cardList, setCardList] = useState<CardProps[]>([]);

    useEffect(() => {
        getEvents(setCardList);
    }, []);

    return (
        <div className='community-section'>
            <p className='community-section-header'>A Welcoming Space For R-land</p>
            <CardContainerComponent list={cardList} />
        </div>
    );
}

export default Community;
