import React from "react";

interface IScrollDragProps {
    ref?: any;
    rootClass?: string;
}

interface IScrollDragStates {
    startX?: number;
    scrollLeft?: any;
    isScrolling?: boolean;
}

export class ScrollDrag extends React.Component<
    IScrollDragProps,
    IScrollDragStates
> {
    ref: any;

    constructor(props: any) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            isScrolling: false,
            startX: 0,
            scrollLeft: 0,
        };
    }

    onMouseDown = (e: any) => {
        this.setState({
            ...this.state,
            isScrolling: true,
            startX: e.pageX - this.ref.current.offsetLeft,
            scrollLeft: this.ref.current.scrollLeft,
        });
    };
    onMouseLeave = (e: any) => {
        this.setState({ ...this.state, isScrolling: false });
    };

    onMouseUp = () => {
        this.setState({ ...this.state, isScrolling: false });
    };

    onMouseMove = (e: any) => {
        const { startX, scrollLeft } = this.state;
        if (this.state.isScrolling) {
            this.ref.current.scrollLeft =
                scrollLeft - e.pageX + this.ref.current.offsetLeft + startX;
        }
    };

    render() {
        const { rootClass } = this.props;
        return (
            <div
                ref={this.ref}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                onMouseLeave={this.onMouseLeave}
                className={rootClass}
            >
                {this.props.children}
            </div>
        );
    }
}

export default ScrollDrag;
