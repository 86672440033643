import axios from "./axios";
import { TimelineEvent } from "../components/HomePage/Timeline";

export const getTimelineEvents: (
  setTimelineEvents: React.Dispatch<React.SetStateAction<TimelineEvent[]>>
) => void = (setTimelineEvents) =>
  axios
    .get("/timeline-events")
    .then((response) => {
      setTimelineEvents(response.data);
    });
