import "react-notion/src/styles.css";
import { BlockMapType, NotionRenderer } from "react-notion";
import { useEffect, useState } from "react";
import { getSOCDNotion } from "../../Api/notion_api";

const SOCPage: React.FC = () => {
  const [result, setResult] = useState<BlockMapType>({});

  useEffect(() => {
    getSOCDNotion(setResult);
  }, []);

  return (
    <div
      className="fullpage-section socd-page"
      data-anchor="socd-page"
      key="socd-page"
      id="socd-page"
    >
      <div style={{ height: "100vh", overflowY: "scroll" }}>
        <NotionRenderer blockMap={result} fullPage />
      </div>
    </div>
  );
};

export default SOCPage;
