import React from 'react';
import CardComponent, { CardProps } from '../HomePage/Card';

interface CardConatinerProp {
    list: CardProps[];
}

const CardContainerComponent: React.FC<CardConatinerProp> = props => {
    return (
        <div className='card-container'>
            {props.list.map(item => <div className='card-component'> <CardComponent {...item} /></div>)}
        </div>
    );
}

export default CardContainerComponent;
