import React, { useEffect, useState } from "react";
import tiltArrow from "../../resources/assets/call_made_24px.svg";
import { Contact } from "./Home";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { getMdgSocialMedia } from "../../Api/mdg_social_media";

export interface SocialMedia {
  name: string;
  url: string;
  image: { url: string };
}

export interface MdgSocialMedia {
  social_media: SocialMedia;
}

export const SocialLinkComponent: React.FC = () => {
  const [socialMediaList, setSocialMediaList] = useState<MdgSocialMedia[]>([]);

  useEffect(() => {
    getMdgSocialMedia(setSocialMediaList);
  }, []);

  return (
    <div className="footer-social-link">
      {socialMediaList.map((mediaProp: MdgSocialMedia) => (
        <a target="_blank" href={mediaProp.social_media.url}>
          <img
            className="footer-social-media"
            src={mediaProp.social_media.image.url}
            alt={mediaProp.social_media.name}
          ></img>
        </a>
      ))}
    </div>
  );
};

const ContactComponent: React.FC<Contact> = (props) => {
  const { email, chat, phone } = props;
  return (
    <div className="footer-contact">
      <p className="footer-contact-address">
        MDG Space, Ground floor, SAC Building IIT Roorkee, Uttarakhand, India -
        247667
      </p>
      <div className="footer-contact-handles">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="1">Click to Copy</Tooltip>}
        >
          <p
            className="footer-contact-email"
            onClick={() => {
              navigator.clipboard.writeText(email);
            }}
          >
            Email: {email}
          </p>
        </OverlayTrigger>
        {phone && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="2">Click to Copy</Tooltip>}
          >
            <p
              className="footer-contact-phone"
              onClick={() => {
                navigator.clipboard.writeText(phone);
              }}
            >
              Call: {phone}
            </p>
          </OverlayTrigger>
        )}
      </div>
      <a className="footer-chat-anchor" target="_blank" href={chat}>
        <div className="footer-contact-chat">
          <span className="footer-chat-text">Chat with us</span>
          <img src={tiltArrow} alt="chat" />
        </div>
      </a>
    </div>
  );
};

const FooterIntroComponent: React.FC = () => {
  return (
    <div className="footer-intro">
      <div className="footer-intro-text">
        <p>
          MDG Space has a bunch of software wizards who enjoy crafting spells
          with design and code to engineer robust and innovative solutions. Our
          esoteric space is involved in a myriad of domains asides from App
          Development. We actively foster the Open Source Culture and other
          tech-based know-how within the IIT Roorkee campus and beyond.
        </p>
      </div>
      <SocialLinkComponent />
    </div>
  );
};

const Footer: React.FC<Contact> = (props) => {
  return (
    <div className="footer-container">
      <div className="custom-footer">
        <FooterIntroComponent />
        <ContactComponent {...props} />
      </div>
      <p className="footer-copyright">MDG All Hands | Copyright 2022</p>
    </div>
  );
};

export default Footer;
