import React, { useState } from 'react';
import Mobile from '../HomePage/Mobile';
import ProjectOverlay from '../HomePage/ProjectOverlay';

export interface CarouselItemProps {
    name: string;
    logo: { url: string };
    desc_img: { url: string };
    title: string;
    desc: string;
    button_text: string;
    color?: string;
    text_color?: string;
    id: number;
    button_link: string;
    product_type: string;
    contribution_link?: string;
    type?: string;
    button_color?: string;
}

const CarouselItem: React.FC<CarouselItemProps> = props => {
    const { name, logo, desc_img, title, desc, button_text, button_link, product_type, color, text_color, contribution_link, type ,button_color } = props;

    const [hoverState, setHoverState] = useState(false);

    const toggleHoverState: () => void = () => {
        setHoverState(!hoverState);
    };

    var style;

    if (hoverState) {
        style = {
            transform: 'scale(1.1 , 1.1)',
            backgroundColor: button_color === null ? color : button_color!,
            color: button_color === null ? text_color : color!,
            border: `2px solid ${button_color === null ? color : button_color!}`,
        };
    } else {
        style = {
            color: button_color === null ? color : button_color!,
            border: `2px solid ${button_color === null ? color : button_color!}`,
        };
    }

    return (
        <div className='project-carousel'>
            <div className="project">
                <div className="project-item">
                    <div className="project-overview">
                        <Mobile content={<ProjectOverlay image={desc_img!.url} />} type={type} />
                        <div className={`project-description ${type! === 'mobile' ? 'project-description-mobile' : (type! === 'mobile_landscape' ? 'project-description-mobile-landscape' : '')}`}>
                            {product_type === 'app' ?
                                <div className='project-info'>
                                    <img width='50vw' src={logo!.url} alt='project-logo' />
                                    <p className='project-name'>{name}</p>
                                </div>
                                : <div className="project-info">LIBRARY</div>
                            }
                            <p className="project-header">{title}</p>
                            <p className="project-detail">{desc}</p>
                            {contribution_link && <a href={contribution_link} target='_blank' className="project-contribute-link">Contribute to {name}</a>}
                            <a target='_blank' href={button_link} className='get-project-button'
                                style={style}
                                onMouseEnter={toggleHoverState}
                                onMouseLeave={toggleHoverState}
                            >
                                {button_text}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarouselItem;
