import React from "react";
import Card from "react-bootstrap/Card";

export interface CardProps {
  img: { url: string };
  title?: string;
  desc?: string;
  link?: string;
  link_text?: string;
  button_text?: string;
}

const CardComponent: React.FC<CardProps> = (props) => {
  var { img, title, desc, link, link_text, button_text } = props;

  let link_texts: string[] | undefined,
    button_texts: string[] | undefined,
    links: string[] | undefined;

  if (link && (link_text || button_text)) {
    if (link_text) link_texts = link_text.split("|", 10);
    if (button_text) button_texts = button_text.split("|", 10);

    links = link.split("|", 10);
  }

  return (
    <Card className="custom-card">
      <Card.Img className="img" src={img.url} alt="image" />
      <Card.Body>
        {title && (
          <Card.Title className="custom-card-title">{title}</Card.Title>
        )}
        {desc && <Card.Text className="custom-card-text">{desc}</Card.Text>}
        <div className="custom-card-link-container">
          {link_texts &&
            link_texts.map((text, index) => (
              <a
                target="_blank"
                href={links![index]}
                className="custom-card-link-text"
              >
                {text}
              </a>
            ))}
        </div>
        {button_texts &&
          button_texts.map((text, index) => (
            <a target="_blank" href={links![index]} className="card-button">
              {text}
            </a>
          ))}
      </Card.Body>
    </Card>
  );
};

export default CardComponent;
